(function ($) {
  'use strict';

  var mapsOptions = {
    zoom: 15,
    panControl: false,
    rotateControl: false,
    scaleControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    scrollwheel: false,
    zoomControl: false,
    styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
  };

  function initMain(mapDiv) {
    var map = {
      options: mapsOptions,
      center: new google.maps.LatLng(mapDiv.data('lat'), mapDiv.data('lng')),
      domObj: mapDiv.get(0)
    }

    map.map = new google.maps.Map(map.domObj, map.options);
    map.map.setCenter(map.center);

    map.marker = new google.maps.Marker({
      position: map.center,
      map: map.map,
      animation: google.maps.Animation.DROP,
      icon: {
        url: templateDir + '/assets/img/pin.svg',
        scaledSize: new google.maps.Size(27, 39),
        origin: new google.maps.Point(0,0)
      },
    });

    google.maps.event.addDomListener(window, 'resize', center(map.map, map.center));
  }

  function initModal(mapDiv, markers) {
    var map = {
      options: mapsOptions,
      domObj: mapDiv.get(0),
      markers: [],
      currentMarker: 0
    }

    map.map = new google.maps.Map(map.domObj, map.options);

    markers.each(function(idx, marker){
      map.markers.push(
        new google.maps.Marker({
          position: new google.maps.LatLng($(marker).data('lat'), $(marker).data('lng')),
          map: map.map,
          animation: google.maps.Animation.DROP,
          icon: {
            url: templateDir + '/assets/img/pin.svg',
            scaledSize: new google.maps.Size(27, 39),
            origin: new google.maps.Point(0,0)
          }
        })
      );
    });

    center(map.map, map.markers[map.currentMarker].position);

    google.maps.event.addDomListener(window, 'resize', center(map.map, map.markers[map.currentMarker].position));

    var modal = mapDiv.closest('.map-modal');

    markers.on('click', function(e){
      $('body').addClass('fixed');
      var idx = $(e.currentTarget).closest('.contact').index();
      center(map.map, map.markers[idx].position);
      map.currentMarker = idx;

      modal.css('display', 'flex').hide().fadeIn(300);
    });
  }

  function center(map, center) {
    map.setCenter(center);
  }

  if ($('.map #map').length > 0) {
    google.maps.event.addDomListener(window, 'load', initMain($('.map #map')));
  }

  if ($('.map-toggler').length > 0 && $('.map-modal #map').length > 0) {
    google.maps.event.addDomListener(window, 'load', initModal($('.map-modal #map'), $('.map-toggler')));
  }
})(jQuery);
