var parallaxes = [];
var isMobile = $(window).width() <= 785;

var onLoad = function (event) {
    if ($('.hero .slider').length > 0) {
      $('.hero .slider').owlCarousel({
        loop: true,
        items: 1,
        nav: true,
        dots: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        autoplay: true,
        autoplayTimeout: 5000,
        smartSpeed: 600,
      });
    }

    if ($('.article-page .article-slider').length > 0) {
      $('.article-page .article-slider').owlCarousel({
        loop: true,
        items: 1,
        nav: true,
        dots: false,
        autoHeight: true,
      });
    }

    if ($('.hero .families-slide').length > 0) {
      $('.hero .families-slide').owlCarousel({
        autoWidth: true,
        nav: false,
        dots: false,
      });
    }

    if ($('.product-inner').length > 0) {
      $('.product-inner').addClass('loaded');
    }

    if ($('.balance.changing').length > 0) {
      $('body').on('click', '.balance.changing .rate', function () {
        $(this).addClass('active').siblings('.active').removeClass('active');
        changeBalances($('.balance.changing .rate.active'));
      });

      changeBalances($('.balance.changing .rate.active'));
    }

    if (isMobile) {
      buildSliders();
    }

    onResize();
  },
  onResize = function (event) {
    if ($('.news-list .article').length > 0) {
      var articles = $('.news-list .article');

      for (var i = 0; i < articles.length; i++) {
        var content = articles.eq(i).find('.content'),
          text = content.find('.text'),
          heading = content.find('.text > h4');
        (goner = text.find('.goner')), (gonerInner = goner.find('.inner'));

        goner.css({
          height: parseFloat(gonerInner.css('height')),
        });

        content.css({
          height:
            parseFloat(content.css('padding-top')) * 2 +
            parseFloat(text.css('padding-top')) * 2 +
            parseFloat(heading.css('height')) +
            parseFloat(gonerInner.css('height')),
        });
      }

      if (!$('.news-list').hasClass('loaded')) {
        $('.news-list').addClass('loaded');
      }
    }

    if (window.innerWidth > 991) {
      $('.menu-toggler').removeClass('active');
      $('.menu').removeAttr('style');
      $('.menu .has-children:not(.langs) .nav-sub').removeAttr('style');
      $('body').removeClass('fixed');
    }

    if ($('.about .chart .pins').length > 0) {
      var bounds = $(
        '.about .block.wide .chart .svg_chart #firstPin .pinCenter'
      )
        .get(0)
        .getBoundingClientRect();
      var chartB = $('.about .block.wide .chart')
        .get(0)
        .getBoundingClientRect();

      console.log(bounds, chartB);

      $('.about .chart .pins.firstPin').css({
        top: bounds.top - chartB.top,
        left: bounds.left + bounds.width / 2 - chartB.left,
      });

      var bounds = $(
        '.about .block.wide .chart .svg_chart #secondPin .pinCenter'
      )
        .get(0)
        .getBoundingClientRect();

      $('.about .chart .pins.secondPin').css({
        top: bounds.top - chartB.top,
        left: bounds.left + bounds.width / 2 - chartB.left,
      });
    }

    onScroll();
  },
  onScroll = function (event) {
    if ($('.products-list .product:not(.found)').length > 0) {
      $('.products-list .product:not(.found)').each(function (i, prod) {
        var prodTop =
          $(prod).offset().top + parseFloat($(prod).css('height')) / 3;
        var mark = window.pageYOffset + (window.innerHeight * 3) / 4;

        if (mark > prodTop) {
          $(prod).addClass('found');
        }
      });
    }

    if ($('.families-list .family:not(.found)').length > 0) {
      $('.families-list .family:not(.found)').each(function (i, fam) {
        var famTop = $(fam).offset().top + parseFloat($(fam).css('height')) / 3;
        var mark = window.pageYOffset + (window.innerHeight * 3) / 4;

        if (mark > famTop) {
          $(fam).addClass('found');
        }
      });
    }

    if ($('.about .foundables:not(.found)').length > 0) {
      $('.about .foundables:not(.found)').each(function (i, fam) {
        var famTop = $(fam).offset().top + parseFloat($(fam).css('height')) / 3;
        var mark = window.pageYOffset + (window.innerHeight * 3) / 4;

        if (mark > famTop) {
          $(fam).addClass('found');

          if ($(fam).find('.roll').length > 0) {
            console.log($(fam).find('.roll'));
            $(fam)
              .find('.roll')
              .each(function (i, roll) {
                countUp($(roll), $(roll).data('no'));
              });
          }
        }
      });
    }

    if (
      $('.content-box:not(.found)').length > 0 &&
      $('.content-box:not(.content-box--hidden)').length > 0
    ) {
      $('.content-box:not(.found)') &&
        $('.content-box:not(.content-box--hidden)').each(function (i, fam) {
          var conBTop =
            $(fam).offset().top + parseFloat($(fam).css('height')) / 3;
          var mark = window.pageYOffset + (window.innerHeight * 3) / 4;

          if (mark > conBTop) {
            $(fam).addClass('found');

            if ($(fam).find('.roll').length > 0) {
              console.log($(fam).find('.roll'));
              $(fam)
                .find('.roll')
                .each(function (i, roll) {
                  countUp($(roll), $(roll).data('no'));
                });
            }
          }
        });
    }
  },
  setCookie = function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  },
  changeBalances = function (active) {
    var brands = active.data('brands');
    var id = active.data('id');

    $('.balance.changing').addClass('animate');
    $('.balance.changing .families_items-block.active').removeClass('active');
    $('.balance.changing .families_texts-item.active').removeClass('active');
    $('.balance.changing .families_texts-item#' + id).addClass('active');

    for (var i = 0; i < brands.length; i++) {
      $('.balance.changing .families_items-block#' + brands[i]).addClass(
        'active'
      );
    }
  },
  countUp = function (counter, countTo) {
    $({ countNum: counter.text() }).animate(
      {
        countNum: countTo,
      },
      {
        duration: 600,
        easing: 'linear',
        step: function () {
          counter.text(Math.floor(this.countNum));
        },
        complete: function () {
          counter.text(counter.data('no'));
        },
      }
    );
  };

function buildSliders() {
  var splides = document.querySelectorAll('.splide');
  splides.forEach(function (splide) {
    var slides = splide.querySelectorAll('.splide__slide');
    if (slides.length <= 1) {
      splide.classList.add('single');
      return;
    } else {
      var update = function update() {
        var counter = splide.querySelector('.counter');
        var bar = splide.querySelector('.progress-bar');
        counter.textContent = ''
          .concat(slider.index + 1, '/')
          .concat(slider.length);
        var end = slider.length;
        var rate = Math.min((slider.index + 1) / end, 1);
        bar.style.width = String(100 * rate) + '%';
      };
      var slider = new Splide(splide, {
        perPage: 1,
        type: 'horizontal',
        autoplay: false,
        arrows: false,
        pagination: false,
        gap: 24,
        padding: {
          "right": 40,
        },
      });
      slider.mount();
      update();
      slider.on('move', update);
    }
  });
}

$(window).on('scroll', onScroll);
$(window).on('resize', onResize);
$(window).on('load', onLoad);

$('textarea, input').on('change keyup keydown keypress', function () {
  if ($.trim(this.value).length > 0) {
    $(this).closest('.input').removeClass('invalid');
  }
});

$('.form').on('submit', function (e) {
  e.preventDefault();

  var form = $(this);
  var endpoint = form.attr('data-form');
  var formObj = new FormData(form[0]);

  $.ajax({
    url: endpoint,
    data: formObj,
    processData: false,
    contentType: false,
    type: 'POST',
    success: function (data) {
      form.siblings('.form-success').css('display', 'flex').hide().fadeIn(300);
      setTimeout(function () {
        $('.form-modal .close').trigger('click');
      }, 5000);
    },
  });
});

$('body').on('click', '.js-expand', function () {
  $('.rules .content').toggleClass('expanded');
  $(this).toggleClass('expanded');
});

$('body').on('click', '.goDown', function () {
  var sel = '';

  if (!iOS) {
    sel = 'body, html';
  } else {
    sel = 'body';
  }

  var where = 0;

  if ($(this).closest('section').next().length > 0) {
    where = $(this).closest('section').next().offset().top;
  } else {
    where = $('.footer').offset().top;
  }

  $(sel)
    .stop()
    .animate({ scrollTop: where }, 750, 'swing', function () {
      $(window).scrollTop(where);
    });
});

$('body').on('click', '.iframe-inner-wrap:not(.clicked)', function () {
  var wrap = $(this);
  wrap.addClass('clicked');
  var url = wrap.data('iframe-url');
  wrap.append("<iframe src='" + url + "?autoplay=1'>");

  setTimeout(function () {
    wrap.find('iframe').addClass('put');
  }, 0);
});

$('body').on('click', '.notif .button', function () {
  setCookie('cookiesAlright', 'true', 100);
});

$('body').on('click', '.notif .close, .notif .button', function () {
  $(this)
    .closest('.notif')
    .addClass('clicked')
    .one(tranEv, function () {
      $(this).remove();
    });
});

$('body').on('click', '.menu-toggler', function () {
  $(this).toggleClass('active');

  if (!$(this).hasClass('active')) {
    $('.menu .has-children:not(.langs) .nav-sub').hide();
    $('body').removeClass('fixed');
  } else {
    $('body').addClass('fixed');
  }
});

$('body').on('click', '.nav-item.has-children', function (e) {
  var isAnchor = $(e.target).is('a') || $(e.target).closest('a').length > 0;
  var subMenu =
    $(e.target).is('.nav-sub') || $(e.target).closest('.nav-sub').length > 0;

  if (!isAnchor && !subMenu) {
    e.preventDefault();
    $(this).find('.nav-sub').slideToggle();
  }
});

$('body').on('change', '.mobFilter', function () {
  window.location.href = $(this).val();
});

$('body').on('click', '.formOpen', function (e) {
  $('body').addClass('fixed');

  $('.form-modal').css('display', 'flex').hide().fadeIn(300);
});

$('body').on('click', '.modal', function (e) {
  var targetOut = !(
      $(e.target).is('.box') || $(e.target).closest('.box').length > 0
    ),
    targetClose = $(e.target).is('.close'),
    target = targetOut || targetClose;

  if (target) {
    $(this).fadeOut(300);
    $('body').removeClass('fixed');
  }
});

$('.cleanstuff-page').ready(function () {
  var x = 2; //blocks to show

  checkLoadMore();

  $('#loadMore').click(function (e) {
    e.preventDefault();

    for (var i = 0; i < x; i++) {
      $('.content-box--hidden:eq( 0 )').removeClass('content-box--hidden');
      checkLoadMore();
    }
  });

  // prod_length = $("#products-list > a").length;
  // x = 3;
  // $('#products-list > a:lt(' + x + ')').show().addClass('showing');
  // $('#loadMore').click(function(e) {
  //     e.preventDefault();
  //     x = (x + 3 <= prod_length) ? x + 3 : prod_length;
  //     $('.product:not(.showing)').removeClass('found');
  //     $('#products-list > a:lt(' + x + ')').show().addClass('showing');
  //     if(x == prod_length){
  //       $('#loadMore').hide();
  //   }
  // });
});

function checkLoadMore() {
  if (!$('.content-box--hidden').length) {
    $('#loadMore').hide();
  }
}

// product inner page slider
if ($('.prod-carousel').length > 0) {
  $('.prod-carousel').owlCarousel({
    items: 1,
    nav: true,
    dots: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    freeDrag: false,
    autoplayTimeout: 5000,
    smartSpeed: 600,
  });
}
